<template>
  <div v-loading="tableLoading" >
    <el-table :data="obj.data" empty-text="No Datas." class="table">
      <el-table-column type="index" width="40"></el-table-column>
      <el-table-column label="Referral ID" prop="payFaiUuid" width="300" class="font-bold"></el-table-column>
      <el-table-column label="Commission" prop="inviterMoney" width="300" class="font-bold"></el-table-column>
      <el-table-column label="Status" prop="auditStatus" width="100" class="font-bold">
        <template slot-scope="scope">
          <p v-if="scope.row.auditStatus == 1">granting</p>
          <p v-else-if="scope.row.auditStatus == 2">granted</p>
          <p v-else>Invalid</p>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="obj.count"
    ></el-pagination>
  </div>
</template>

<script>

export default {
  props: {
    obj:{
      type:Object
    },
    // 带有默认值的对象
    data: {
      type: Array
    }
  },
  computed: {
    tableData() {
      return this.data;
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      tableLoading: false
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.$emit("pageChange", val);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>


</style>