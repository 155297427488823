<template>
  <div class="account-content">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle">
      <el-col :span="4" type="flex" align="middle">
        <!-- <div class="head_right_item">
                    <el-row >
                        <el-col :span="24" ><el-button type="text"  @click="openQuanDialogPop">My Voucher</el-button></el-col>
                    </el-row>
                </div> -->
      </el-col>
      <el-col :span="7">
        <div class="account-content-title">
          <p title="email" class="content-left-head-title">
            <label class="">Email：</label>
            <span v-if="this.userInfo.email">
              <span class="normalText">{{ userInfo.email }}</span>
            </span>
            <span v-else>
              <el-button type="text" class="">nil</el-button>
            </span>
          </p>
        </div>
      </el-col>

      <el-col :span="3">
        <div class="account-content-title">
          <span title="Account Balance" class="content-left-head-title"
            >Account Balance
            <h1 />
            RM{{ userInfo.balance }}</span
          >
          <h1 />
        </div>
      </el-col>
      <el-col :span="3">
        <div class="account-content-title">
          <span title="Commission" class="content-left-head-title"
            >Commission
            <h1 />
            RM{{ userInfo.invitePrices }}</span
          >
          <h1 />
        </div>
      </el-col>

      <el-col :span="3">
        <div class="account-content-title">
          <span title="Affiliate Code" class="content-left-head-title"
            >Affiliate Code
            <h1 />
            {{ userInfo.inviteCode }}
          </span>
          <h1 />
        </div>
      </el-col>
      <el-col :span="4">
        <!-- <el-card class="box-card">
                    <div class="font-info"><span   icon="el-icon-user">MemberLevel:</span></div>
                    <div class="member_level"> <span >{{userLevelId}}</span></div>
                        
                </el-card> -->
      </el-col>
    </el-row>

    <el-divider content-position="left"
      >IInvite friends and earn rewards</el-divider
    >

    <div class="content-right-main">
      <div id="invite_div" class="invite_border">
        <el-row class="div_border">
          <el-col :span="5">
            <div class="">
              <p style="font-size: 14px; color: #1b2337">
                Invite your colleagues/friends
              </p>
              <p style="font-size: 12px">Copy the link and send to them</p>
            </div>
          </el-col>
          <el-col :span="14">
            <div class="invite_text">
              <div>
                <span>You are invited to join:</span>
                <span id="invite_host" style="display: inline"></span>
              </div>
              <div>
                <span
                  >Shutterstock, AdobeStock, iStockphoto, 123RF, Dreamstime,
                  etc., high-quality vectors worldwide are all in , register and
                  remember to fill in the invitation code:</span
                >
                <span id="invite_code"></span>
              </div>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="">
              <button class="copy_button" @click="copy_invite">
                Copy Link
              </button>
              <input
                id="invite_link"
                style="width: 0.1px; height: 0.1px;float:left; border:none;"
              />
            </div>
          </el-col>
        </el-row>
        <div class="invite_dec">
          <p style="font-size:12px">About referral reward:</p>
          <p>
            1. Send your exclusive invitation link or invitation code to your
            friends, and they will become your recommended user after successful
            registration through the invitation link;
          </p>
          <p>
            2. Within 1 year from the successful invitation, if your friends
            reload in , you can get a 10% bonus to your account without upper
            bound.
          </p>
          <p>
            3. There is no upper limit for the bonus. You can send your
            invitation link or invitation code to any channel such as friends,
            colleagues, partners, etc. It is not a dream to download without
            spending money!
          </p>
          <p style="color:red">
            * Please do not try to register your own referral link to purchase,
            we will not issue any rewards, bonuses will be issued on the 1st of
            each month after the review passed.
          </p>
        </div>
      </div>
    </div>
    <el-divider content-position="left"></el-divider>
    <Bindmobiledialog
      :dialogVisible="mobileDialogTableVisible"
      @closeDialog="closeMobileDialogDetailPop"
    />
    <Bindemaildialog
      :dialogVisible="emailDialogTableVisible"
      @closeDialog="closeEmailDialogDetailPop"
    />
    <Resetdialog
      :dialogVisible="resetDialogTableVisible"
      @closeDialog="closeResetDialogPop"
    />
    <Inviterecorddialog
      :dialogVisible="inviteDialogTableVisible"
      @closeDialog="closeInviteDialogPop"
    />
    <Vipchargedialog
      :dialogVisible="vipChargeDialogTableVisible"
      @closeDialog="closeVipChargeDialogPop"
    />
    <Quandialog
      :dialogVisible="quanDialogTableVisible"
      @closeDialog="closeQuanDialogPop"
    />
  </div>
</template>

<script>
import Bindmobiledialog from "./bindmobiledialog";
import Bindemaildialog from "./bindemaildialog";
import Resetdialog from "./resetdialog";
import Inviterecorddialog from "./inviterecorddialog";
import Vipchargedialog from "./vipchargedialog";
import Quandialog from "./quandialog";
export default {
  components: {
    Quandialog,
    Vipchargedialog,
    Inviterecorddialog,
    Resetdialog,
    Bindemaildialog,
    Bindmobiledialog,
  },
  name: "info",
  props: {
    userInfo: {
      type: Object,
    },
  },
  data() {
    return {
      imagePath: require("@/assets/images/user.svg"),
      mobileDialogTableVisible: false,
      emailDialogTableVisible: false,
      resetDialogTableVisible: false,
      quanDialogTableVisible: false,
      vipChargeDialogTableVisible: false,
      inviteDialogTableVisible: false,
      userLevelId: "无",
      userVipEndTime: "",
    };
  },
  created() {},
  mounted() {
    if (this.$store.state.common.userInfo.userLevelId != null) {
      //是否已是vip
      //vip未过期
      if (new Date() <= this.$store.state.common.userInfo.vipEndTime) {
        if (this.$store.state.common.userInfo.userLevelId == 2) {
          this.userLevelId = "标准会员";
        } else if (this.$store.state.common.userInfo.userLevelId == 4) {
          this.userLevelId = "高级会员";
        } else if (this.$store.state.common.userInfo.userLevelId == 5) {
          this.userLevelId = "企业会员";
        }
        this.userVipEndTime = this.formatDate(
          this.$store.state.common.userInfo.vipEndTime
        );
      }
    }
  },
  methods: {
    copy_invite() {
      var url = document.getElementById("invite_link");
      url.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.selectText("invite_link");
    },
    selectText(element) {
      var text = document.getElementById(element);
      if (document.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        var selection = window.getSelection();
        var range1 = document.createRange();
        range1.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range1);
        document.execCommand("Copy");
      } else {
        alert("none");
      }
    },
    recharge() {
      this.$router.push({ path: "/pay" });
    },
    privilege() {
      this.$router.push({ path: "/privilege" });
    },
    //绑定手机
    openMobileDialogDetailPop() {
      this.mobileDialogTableVisible = true;
    },
    closeMobileDialogDetailPop() {
      this.mobileDialogTableVisible = false;
      this.getUserList();
    },
    //绑定邮箱
    openEmailDialogDetailPop() {
      this.emailDialogTableVisible = true;
    },
    closeEmailDialogDetailPop() {
      this.emailDialogTableVisible = false;
      this.getUserList();
    },
    //修改密码
    openResetDialogPop() {
      this.resetDialogTableVisible = true;
    },
    closeResetDialogPop() {
      this.resetDialogTableVisible = false;
      this.getUserList();
    },
    //打开我的优惠券列表
    openQuanDialogPop() {
      this.quanDialogTableVisible = true;
    },
    closeQuanDialogPop() {
      this.quanDialogTableVisible = false;
    },
    //vip记录
    openVipChargeDialogPop() {
      this.vipChargeDialogTableVisible = true;
    },
    closeVipChargeDialogPop() {
      this.vipChargeDialogTableVisible = false;
    },
    //邀请记录
    openInviteDialogPop() {
      this.inviteDialogTableVisible = true;
    },
    closeInviteDialogPop() {
      this.inviteDialogTableVisible = false;
    },
    formatDate(timesssss) {
      //格式化时间
      var t = new Date(timesssss); //row 表示一行数据, updateTime 表示要格式化的字段名称
      var year = t.getFullYear(),
        month = t.getMonth() + 1,
        day = t.getDate(),
        hour = t.getHours(),
        min = t.getMinutes(),
        sec = t.getSeconds();
      var newTime =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day) +
        " " +
        (hour < 10 ? "0" + hour : hour) +
        ":" +
        (min < 10 ? "0" + min : min) +
        ":" +
        (sec < 10 ? "0" + sec : sec);
      return newTime;
    },
  },
};
</script>

<style scoped>
.account-content {
  width: 100%;
  height: 100%;
  float: start;
  margin-right: 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fdfdfd;
  position: relative;
}

.account-content .account-content-head-logo {
  width: 90px;
  height: 90px;
  border-radius: 80px;
  box-shadow: 0 2px 0 2px rgba(0, 0, 0, 0.04);
  margin: 15px 30px;
}
.account-content .account-content-head-level {
  width: 90px;
  height: 90px;
  margin: 15px 30px;
}
.account-content .account-content-head-logo .account-content-head-logo-img {
  width: 100%;
  height: 100%;
  text-align: center;
}

.account-content .account-content-title {
  width: 100%;
  height: 100%;
  margin: 5px 15px;
}

.account-content .account-content-title .content-left-head-title {
  font-size: 16px;
  font-family: "Microsoft YaHei", "Times New Roman";
  color: #333;
}

.account-content .account-content-title .content-right-head-title {
  font-size: 14px;
  font-family: "Microsoft YaHei", "Times New Roman";
  color: #333;
}

.account-content .content-right-main {
  position: relative;
  width: 100%;
  height: 100%;
}

.div_border {
  padding: 10px;
  background: #eaeaea;
  border-radius: 10px;
  text-align: center;
}

.invite_border {
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  background: #fdfdfd;
  border-radius: 1px;
  text-align: center;
}

.copy_button {
  color: #4a4a4a;
  margin-top: 5px;
  margin-left: 20px;
  color: #000;
  padding: 5px 15px;
  background-color: #ffffff;
  text-align: center;
  border: 1px solid gray;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
  width: 100px;
}

.invite_text {
  border: 2px solid #fbfbfb;
  border-radius: 20px;
  background-color: #fbfbfb;
  float: left;
  margin-left: 20px;
  min-height: 35px;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

.invite_text span {
  padding: 5px;
}

.invite_dec {
  margin-right: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
  border-radius: 2px;
}
.invite_dec p {
  padding: 3px;
}
.bg-purple-light {
  background: #e5e9f2;
}
.head_item {
  font-size: 16px;
  background: #e5e9f2;
}
.font-info {
  color: #3e3e3e;
  margin: 5px;
  text-align: center; /*水平居中*/
}
.member_level {
  color: #e6a23c;
  margin-top: 10px;
  font-size: 18px;
  text-align: center; /*水平居中*/
}
.member_level_endtime {
  color: #e6a23c;
  margin-top: 10px;
  font-size: 13px;
  text-align: center; /*水平居中*/
}
.member_buttons {
  margin-top: 10px;
}
</style>
